import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Input, Select, Table, Spin, Checkbox, notification } from 'antd';
import {userList, changeAccessUserPart, changeUserStatus} from '../../services/userApi';
import {getAllStates, getStatesTree} from '../../services/adminApi';
import CreateBranchModal from '../../components/CreateBranchModal'

interface TreeNode {
  label: string;
  value: number;
  disabled?: boolean;
  children?: TreeNode[];
}

interface State {
  id: number;
  parentId: number;
  name: string;
  type: string;
  updatedAt: string;
  createdAt: string;
}

const Users: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [states, setStates] = useState<any[]>([]);
  const [roles, setRoles] = useState<any>({ 0: { id: 0, name: 'Пользователь' } });
  const [search, setSearch] = useState('');
  const [state, setState] = useState<number>(0);
  const [role, setRole] = useState<number>(-1);
  const [status, setStatus] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statesForModal, setStatesForModal] = useState<TreeNode[]>([]);

  useEffect(() => {
    setIsLoading(true);
    userList().then(result => {
      setIsLoading(false);
      setUsers(result.users.map((x: any) => ({ ...x, key: x.id })));
      setRoles({...roles, ...result.roles});
    });
    getAllStates().then(result => {
      setIsLoading(false);
      setStates(result.sort((a, b) => a.name.localeCompare(b.name)));
    });
    getStatesTree().then(result => {
      setIsLoading(false);
      setStatesForModal(processTreeData(result));
    });
  }, [])

  const processTreeData = (tree: any[]): TreeNode[] => {
    return tree.map(node => ({
      ...node,
      disabled: node.type !== 'FS',
      children: node.children ? processTreeData(node.children) : [],
    }));
  };

  const handleAddState = (parentId: number, newState: State) => {
    setStates(prevStates => [...prevStates, newState].sort((a, b) => a.name.localeCompare(b.name)));

    setStatesForModal((prevStates) => {
      const updateTree = (nodes: TreeNode[]): TreeNode[] => {
        return nodes.map(node => {
          if (node.value === parentId) {
            return {
              ...node,
              children: [...(node.children || []), {label: newState.name, value: newState.id, disabled: true, children: []}].sort((a, b) => a.label.localeCompare(b.label)),
            };
          } else if (node.children) {
            return { ...node, children: updateTree(node.children) };
          }
          return node;
        });
      };

      return updateTree(prevStates);
    });
  };

  const filterUsers = (users: any) => {
    let result = users;
    if (search.length >= 3) result = result.filter((user: any) => user.name?.toLowerCase()?.indexOf(search) >= 0 || user.email?.toLowerCase()?.indexOf(search) >= 0 || user.fio?.toLowerCase()?.indexOf(search) >= 0 || user.phone?.toLowerCase()?.indexOf(search) >= 0);
    if (state) result = result.filter((user: any) => user.stateId === state);
    if (role >= 0) result = result.filter((user: any) => user.role === role);
    if (status) result = result.filter((user: any) => user.status === status);
    return result;
  }

  const setAccessUserPart = async (userId: number, status: boolean) => {
    if(await changeAccessUserPart(userId, status)) {
      const newUsers = users.map((user:any)=>{
        if(user.id === userId) return {...user, accessUserPart: status};
        return user;
      })
      setUsers(newUsers);
    } else notification.error({ message: 'Произошла ошибка при сохранении' })
  }

  const handleStatusChange = async (userId: number, newStatus: string) => {
    try {
      setIsLoading(true);

      await changeUserStatus(userId, newStatus);

      setUsers(prevUsers =>
          prevUsers.map(user =>
              user.id === userId ? { ...user, status: newStatus } : user
          )
      );

      notification.success({ message: 'Статус успешно обновлён' });
    } catch (error) {
      notification.error({ message: 'Ошибка при изменении статуса' });
    } finally {
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: 'Имя пользователя',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Баланс',
      dataIndex: 'balance',
      key: 'balance',
    },
    {
      title: 'ФИО',
      dataIndex: 'fio',
      key: 'fio'
    },
    {
      title: 'Филиал',
      dataIndex: 'stateName',
      key: 'stateName',
      sorter: (a: any, b: any) => (a.stateName ?? '').localeCompare(b.stateName ?? ''),
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      key: 'role',
      render: (val: number, row: any) => row.isAdmin ? 'Админ' : roles[val].name,
    },
    {
      title: 'Дата регистрации',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Пользовательский доступ',
      dataIndex: 'accessUserPart',
      key: 'accessUserPart',
      render: (val: boolean, row: any) =>  [2,3,4,5,8].includes(row.role) ? <Checkbox checked={!row.role || val} onChange={(e)=>setAccessUserPart(row.id, e.target.checked)}/> : row.access===0?'Заблокирован':'Активен'
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status: string, row: any) => {
        const statusLabels: { [key: string]: string } = {
          'pending': 'Ожидает подтверждения',
          'active': 'Активен',
          'blocked': 'Заблокирован',
        };

        if (row.role === 8) {
          return (
              <Select
                  defaultValue={status}
                  style={{ width: '100%' }}
                  onChange={(value) => handleStatusChange(row.id, value)}
                  options={[
                    { label: 'Ожидает подтверждения', value: 'pending' },
                    { label: 'Активен', value: 'active' },
                    { label: 'Заблокирован', value: 'blocked' },
                  ]}
              />
          );
        }

        return statusLabels[status] || 'Неизвестно';
      },
    },
  ];

  const showingUsers = filterUsers(users);

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} style={{ marginTop: 40, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Col style={{ fontSize: 25 }}>Список пользователей</Col>
        <Col>
          <Button type="primary" onClick={() => setIsModalOpen(true)}>Создать</Button>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginTop: 20 }} >
        <Col sm={8} xs={24}>Поиск:<Input style={{ marginTop: 7 }} placeholder='Имя пользователя / E-mail / ФИО / Телефон' onChange={(e) => { setSearch(e.target.value.toLowerCase()) }} /></Col>
        <Col sm={6} xs={24}>Филиал:<Select style={{ marginTop: 7, width: '100%' }} defaultValue={0} options={[{ label: 'Выберите филиал', value: 0 }, ...states.map(x => ({ value: x.id, label: x.name }))]} onChange={setState} /></Col>
        <Col sm={6} xs={24}>Роль:<Select style={{ marginTop: 7, width: '100%' }} defaultValue={-1} options={[{ label: 'Выберите роль', value: -1 }, ...Object.values(roles).map((x:any) => ({ value: x.id, label: x.name }))]} onChange={setRole} /></Col>
        <Col sm={4} xs={24}>Статус:
          <Select
              style={{ marginTop: 7, width: '100%' }}
              defaultValue=""
              options={[
                { label: 'Все статусы', value: '' },
                { label: 'Ожидает подтверждения', value: 'pending' },
                { label: 'Активен', value: 'active' },
                { label: 'Заблокирован', value: 'blocked' },
              ]}
              onChange={setStatus}
          />
        </Col>
        <Col span={24}>
          <div style={{ fontSize: 14, marginBottom: 10 }}>Всего пользователей: {showingUsers.length}</div>
          <Table
            scroll={{ x: 300 }}
            columns={columns}
            dataSource={showingUsers}
          />
        </Col>
      </Row>
      <CreateBranchModal visible={isModalOpen} onCloseModal={() => setIsModalOpen(false)} states={statesForModal} handleAddState={handleAddState} />
    </Spin>
  );
};

export default Users;
