import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { loginRequest } from '../../services/authApi';

export const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: { email: string; password: string }) => {
    setIsLoading(true);
    try {
      const response = await loginRequest(values);
      if (response?.accessToken) {
        localStorage.setItem('accessToken', response.accessToken);
        window.location.replace('/');
      }
    } catch (err: any) {
      if (err?.message) {
        message.error(err.message);
      } else {
        message.error('Произошла ошибка. Попробуйте снова.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <div style={{ maxWidth: 400, margin: '40px auto', padding: 20 }}>
        <h1 style={{ textAlign: 'center' }}>Вход</h1>

        <Form onFinish={onFinish} layout="vertical">
          <Form.Item label="Электронная почта" name="email" rules={[{ required: true, type: 'email', message: 'Пожалуйста, введите email!' }]}>
            <Input placeholder="Введите электронную почту" />
          </Form.Item>

          <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }]}>
            <Input.Password placeholder="Введите пароль" />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={isLoading}>
              Войти
            </Button>
          </Form.Item>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Link to="/forgot-password">Восстановить пароль</Link>
            <Link to="/signup">Создать аккаунт</Link>
          </div>
        </Form>
      </div>
  );
};

export default Login;