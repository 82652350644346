import React, { useEffect, useState } from 'react';
import { Form, Input, Button, DatePicker, Select, Spin, message } from 'antd';
import { Link } from 'react-router-dom';
import { getAllStates } from "../../services/statesApi";
import { signUpRequest } from '../../services/authApi';
import { State } from '../../@types/state';

export const SignUp: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [states, setStates] = useState<State[]>([]);
    const [state, setState] = useState<number | null>(null);

    const [form] = Form.useForm();

    useEffect(() => {
        const fetchStates = async () => {
            setIsLoading(true);
            try {
                const result = await getAllStates('F');
                setStates(result);
            } catch (err) {
                message.error('Не удалось загрузить данные. Пожалуйста, попробуйте позже или обратитесь к администратору.');
            } finally {
                setIsLoading(false);
            }
        };
        fetchStates();
    }, []);

    const onFinish = async (values: any) => {
        setIsLoading(true);
        try {
            const { firstName, lastName, middleName, birthDate, email, password, storeName, storeAddress } = values;
            const selectedState = states.find(item => item.id === state);
            const response = await signUpRequest({
                firstName,
                lastName,
                middleName,
                birthDate,
                email,
                password,
                stateId: state || 0,
                stateName: selectedState ? selectedState.name : '',
                storeName,
                storeAddress,
            });

            if (response?.user) {
                message.success({ content: 'Пользователь успешно создан.', duration: 10 });
                window.location.replace('/login');
            }
        } catch (err: any) {
            if (err?.message) {
                message.error(err.message);
            } else {
                message.error('Произошла ошибка. Попробуйте снова.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{ maxWidth: 600, margin: '20px auto', padding: 20 }}>
            <h1 style={{ textAlign: 'center' }}>Регистрация</h1>

            <Spin spinning={isLoading}>
                <Form form={form} onFinish={onFinish} layout="vertical" initialValues={{ remember: true }}>
                    <Form.Item label="Фамилия" name="lastName" rules={[{ required: true, message: 'Пожалуйста, введите фамилию!' }]}>
                        <Input placeholder="Введите фамилию" />
                    </Form.Item>

                    <Form.Item label="Имя" name="firstName" rules={[{ required: true, message: 'Пожалуйста, введите имя!' }]}>
                        <Input placeholder="Введите имя" />
                    </Form.Item>

                    <Form.Item label="Отчество" name="middleName" rules={[{ required: true, message: 'Пожалуйста, введите отчество!' }]}>
                        <Input placeholder="Введите отчество" />
                    </Form.Item>

                    <Form.Item label="Дата рождения" name="birthDate" rules={[{ required: true, message: 'Пожалуйста, выберите дату рождения!' }]}>
                        <DatePicker style={{ width: '100%' }} placeholder="Выберите дату рождения" format="DD.MM.YYYY"/>
                    </Form.Item>

                    <Form.Item label="Электронная почта" name="email" rules={[{ required: true, type: 'email', message: 'Пожалуйста, введите корректный email!' }]}>
                        <Input placeholder="Введите почту" />
                    </Form.Item>

                    <Form.Item label="Пароль" name="password" rules={[{ required: true, message: 'Пожалуйста, введите пароль!' }, { min: 8, message: 'Пароль должен быть минимум 8 символов!' }]}>
                        <Input.Password placeholder="Введите пароль" />
                    </Form.Item>

                    <Form.Item label="Подразделение" name="stateId" rules={[{ required: true, message: 'Пожалуйста, выберите подразделение!' }]}>
                        <Select
                            placeholder="Выберите подразделение"
                            style={{ marginTop: 7, width: '100%' }}
                            options={states.map((x) => ({ value: x.id, label: x.name }))}
                            onChange={setState}
                            allowClear
                        />
                    </Form.Item>

                    <Form.Item label="Название вашего магазина" name="storeName">
                        <Input placeholder="Введите название магазина" />
                    </Form.Item>

                    <Form.Item label="Город и адрес магазина" name="storeAddress">
                        <Input.TextArea placeholder="Введите город и адрес магазина" />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block disabled={isLoading}>
                            Зарегистрироваться
                        </Button>
                    </Form.Item>

                    <div style={{ textAlign: 'center' }}>
                        Уже есть аккаунт? <Link to="/login">Войти</Link>
                    </div>
                </Form>
            </Spin>
        </div>
    );
};

export default SignUp;
