import { get, post } from './fetch';

export const saveTest = async (materialId: number, questions: any, answers: any): Promise<void> => {
  await post('/test/save', { materialId, questions, answers });
};

export const questionsList = async (id: number):
  Promise<Array<{
    text: string,
    rightAnswersCount: number,
    answers: Array<{ id: number, text: string, isRight: number }>
  }>> => {
  return await get('/test/questions?id=' + id);
};

export const userPrizes = async (userId: number):
  Promise<{
    success: Array<{
      prize: number,
      attemptsCount: number,
      updatedAt: string,
      name: string
    }>
  }> => {
  return await get('/test/userPrizes?userId=' + userId);
};

export const dashboardData = async (period: Array<string>): Promise<{
  allUsersCount: number;
  periodUsersCount: number;
  allAttendanceCount: number;
  periodAttendanceCount: number;
  allTestCount: number;
  periodTestCount: number;
  sumPrize: number;
  periodSumPrize: number;
  attendanceByState: { state: string; count: number }[];
  prizeByState: { state: string; prize: number }[];
  prizeByUser: { fio: string; state: string; prize: number }[];
  allTestsPopularity: { id: number; name: string; imgSource: string; popularity: number }[];
  attendanceChart: { name: string; 'посещаемость': number }[];
}> => {
  return await post('/user/dashboard', { period });
};

export const getReport = async (state: number | string, period: string[], content: Array<string | number | boolean>): Promise<Blob> => {
  return await post('/admin/report', { state, period, content }, true, 'blob');
};

export const getManagerList = async (id: number | null): Promise<Array<{ id: number, name: string }>> => {
  return await get('/admin/managers?stateId=' + id);
};

export const getNoticeCount = (): Promise<number> => {
  return get('/admin/unapprovedPrizeCount');
};

export const handlePrizeRequest = async (prizeId: number, status: number, reason: string) => {
  return post('/test/handlePrizeRequest', { prizeId, status, reason });
};

export const getAllStates = async (type: string = 'F'): Promise<[{ name: string, id: number }]> => {
  return get('/admin/allStates?type=' + type);
};

export const getStatesTree = async (): Promise<[{ name: string, id: number, type: string }]> => {
  return get('/admin/statesTree');
};

export const setMaterialAccess = async () => {
  return post('/admin/setMaterialAccess', {});
};

export const getStateUsersByClients = async (stateId: number, period: {staer: string, end: string}, materials: string[]): Promise<any> => {
  return post(`/admin/stateUsers`, {
    stateId,
    period,
    materials
  });
};

export const createState = async (parentId:number, name: string, type: string): Promise<any> => {
  return post('/admin/createState', {parentId, name, type});
};