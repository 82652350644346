import React, { useState } from 'react';
import { Modal, Input, TreeSelect, Button, Spin, message } from 'antd';
import { createState } from '../services/adminApi';

interface TreeNode {
    label: string;
    value: number;
    disabled?: boolean;
    children?: TreeNode[];
}

interface State {
    id: number;
    parentId: number;
    name: string;
    type: string;
    updatedAt: string;
    createdAt: string;
}

interface CreateBranchModalProps {
    visible: boolean;
    onCloseModal: () => void;
    states: TreeNode[];
    handleAddState: (parentId: number, newState: State) => void;
}

const CreateBranchModal: React.FC<CreateBranchModalProps> = ({ visible, onCloseModal, states, handleAddState }) => {
    const [name, setName] = useState<string>('');
    const [stateNetworkId, setStateNetworkId] = useState<number | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        onCloseModal();
        resetFields();
    };

    const resetFields = () => {
        setName('');
        setStateNetworkId(null);
    };

    const handleSave = async () => {
        if (!name || !stateNetworkId) {
            message.error('Пожалуйста, заполните все поля!');
            return;
        }

        setIsLoading(true);
        try {
            const newState = await createState(stateNetworkId, name, 'F');
            handleAddState(stateNetworkId, newState);
            message.success('Филиал успешно создан!');
            handleClose();
        } catch (error) {
            message.error('Ошибка при создании филиала!');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            title="Создание нового филиала"
            open={visible}
            onCancel={handleClose}
            footer={[
                <Button key="cancel" onClick={handleClose}>
                    Закрыть
                </Button>,
                <Button key="save" type="primary" onClick={handleSave} loading={isLoading} disabled={!name || !stateNetworkId}>
                    Сохранить
                </Button>,
            ]}
        >
            <Spin spinning={isLoading}>
                <div style={{ marginBottom: 10 }}>
                    <label>Название Филиала</label>
                    <Input placeholder="Введите название филиала" value={name} onChange={e => setName(e.target.value)} />
                </div>
                <div style={{ marginBottom: 10 }}>
                    <label>Привязать к филиальной сети</label>
                    <TreeSelect
                        style={{ width: '100%' }}
                        value={stateNetworkId}
                        treeData={states}
                        placeholder="Выберите филиал"
                        treeDefaultExpandAll={true}
                        onChange={(val) => setStateNetworkId(val)}
                    />
                </div>
            </Spin>
        </Modal>
    );
};

export default CreateBranchModal;
