import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Input, Select, Tabs } from 'antd';
import ImgUploader from '../../components/imgUploader';
import FileUploader from '../../components/fileUploader';
import { CloseOutlined } from '@ant-design/icons';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import { Link } from 'react-router-dom';
import type { TabsProps } from 'antd';
import { oneMaterial } from '../../services/materialsApi';
import { useParams, useNavigate } from "react-router-dom";
import { categoryListByCanAddMaterial } from '../../services/categoryApi';
import { post } from '../../services/fetch';

const content = [24, 22, 20, 18, 16];
const margin = [0, 1, 2, 3, 4];
const defaultFonts = [
  "Mulish",
  "Arial",
  "Courier New",
  "Impact",
  "Georgia",
  "Tahoma",
  "Trebuchet MS",
  "Verdana",

];

const NewMaterial: React.FC = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  useEffect(() => {
    categoryListByCanAddMaterial(1).then(result => { setCategories(result) })
    if (id) oneMaterial(+id, 0).then(result => {
      setMaterial({
        id,
        name: result.name,
        time: result.timeForLearn,
        testTime: result.testTime,
        categ: result.categoryId,
        prizes: result.prize?.split(';') || [],
        questionsAmount: result.questionsAmount,
      });
      setHtml(result.html);
      if (result.imgSource) setImgList([{
        uid: '0',
        name: result.imgSource,
        status: 'done',
        url: '/api/public/materials/img/' + result.imgSource,
      },])
      if (result.fileSource) setFileList([{
        uid: '0',
        name: result.fileSource,
        status: 'done',
        url: '/api/public/materials/img/' + result.fileSource,
      },])
    })
  }, [])
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imgList, setImgList] = useState<UploadFile[]>([]);
  const [categories, setCategories] = useState<any>([]);
  const [material, setMaterial] = useState<any>({ name: '', time: 0, categ: 0, id: 0, testTime: 600, questionsAmount: 15, prizes: [] });
  const [html, setHtml] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Текст`,
      children:
        <SunEditor
          lang="ru"
          setContents={html}
          onChange={(data) => setHtml(data)}
          placeholder="Введите текст"
          setDefaultStyle={"font-family: Mulish; font-size: 16px;"}
          setOptions={{
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript"
              ],
              ["fontColor", "hiliteColor"],
              ["align", "list", "lineHeight"],
              ["outdent", "indent"],
              ["table", "horizontalRule", "link"],
              ["preview", "print"],
              ["removeFormat"],
              ['image'],
              ['codeView']
            ],
            defaultTag: "div",
            minHeight: "300px",
            font: defaultFonts
          }}
        />,
    },
    {
      key: '2',
      label: `Презентация`,
      children: <FileUploader fileList={fileList} setFileList={setFileList} />,
    },
  ];

  const updateFile = async (id: string, type: string) => {
    const list = type === 'img' ? imgList : fileList;
    if (!list[0] || list[0].uid === '0') return;
    const formData = new FormData();
    formData.append('file', list[0] as RcFile);
    formData.append('type', type);
    formData.append('id', id);
    await post('/material/saveFiles', formData, false)
  }

  const handleSave = async () => {
    if (!material.testTime || !material.questionsAmount || !material.time || !material.categ || material.name === "" || imgList.length === 0 || (fileList.length === 0 && html === "")) {
      alert('Заполните все поля');
      return;
    }
    setIsLoading(true);
    const newId = await post('/material/save', { 
      'name': material.name, 
      'categ': material.categ, 
      'time': material.time, 
      'prizes':material.prizes.join(';'), 
      'html': html, 'id': material.id, 
      'testTime': material.testTime, 
      'questionsAmount': material.questionsAmount 
    });
    await updateFile(String(newId), 'img');
    await updateFile(String(newId), 'file');
    setIsLoading(false);
    navigate("/admin/materials/" + material.id);
  };
  const savePrize = (position: number, value: string) => {
    const prizes = [...material.prizes];
    prizes[position] = value;
    if (position === 0) {
      prizes[1] = prizes[2] =  Math.round(Number(value) / 2)
    }
    setMaterial({ ...material, prizes })
  }
  return (
    <Row style={{ margin: '30px 0' }}>
      <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
      <Col xs={content[0]} sm={content[1]} md={content[2]} lg={content[3]} xl={content[4]}>
        <Row gutter={[16, 16]} >
          <Col span={12} style={{ fontSize: 25 }}>
            {material.id ? 'Изменение материала №' + material.id : 'Новый материал'}
          </Col>
          <Col span={24}>
            Название
          </Col>
          <Col span={24}>
            <Input value={material.name} onChange={(e) => setMaterial({ ...material, name: e.target.value })} />
          </Col>
          <Col>
            Обложка для обучения
          </Col>
          <Col span={24} style={{ color: '#B6B6B6', fontSize: 14 }}>
            <ImgUploader fileList={imgList} setFileList={setImgList} />
            Максимальный размер изображения 30 Мб
          </Col>
          <Col span={12}>
            Время для изучения, мин.
          </Col>
          <Col span={24}>
            <Input value={material.time} onChange={(e) => setMaterial({ ...material, time: Number(e.target.value) })} />
          </Col>
          <Col span={12}>
            Время для прохождения теста, секунд.
          </Col>
          <Col span={24}>
            <Input value={material.testTime} onChange={(e) => setMaterial({ ...material, testTime: Number(e.target.value) })} />
          </Col>
          <Col span={12}>
            Баллов за успешное прохождение теста
          </Col>
          <Col span={24}>
            <Row style={{ marginTop: -10 }}>
              <Col span={8}>1-е прохождение<Input value={material.prizes[0]} onChange={(e) => savePrize(0, e.target.value)} /></Col>
              <Col span={8}>2-е прохождение<Input value={material.prizes[1]} onChange={(e) => savePrize(1, e.target.value)} /></Col>
              <Col span={8}>{"> 2-го прохождения"}<Input value={material.prizes[2]} onChange={(e) => savePrize(2, e.target.value)} /></Col>
            </Row>
          </Col>
          <Col span={12}>
            Количество вопросов в тесте
          </Col>
          <Col span={24}>
            <Input value={material.questionsAmount} onChange={(e) => setMaterial({ ...material, questionsAmount: Number(e.target.value) })} />
          </Col>
          <Col span={12}>
            Категория
          </Col>
          <Col span={24}>
            <Select style={{ width: '100%' }}
              placeholder='Выберите категорию'
              value={material.categ ? material.categ : null}
              onChange={(val) => setMaterial({ ...material, categ: val })}
              options={[
                ...categories.map((x: any) => ({ value: x.id, label: x.name }))
              ]}
            />
          </Col>
          <Col span={24}>
            <Tabs defaultActiveKey="1" items={items} />
          </Col>
          <Col span={24} onClick={handleSave}><Button loading={isLoading} type='primary'>Сохранить</Button></Col>
        </Row>
      </Col>
      <Col xs={margin[0]} sm={margin[1]} md={margin[2]} lg={margin[3]} xl={margin[4]}></Col>
      <Link to={'/admin/materials/' + material.id}><div style={{ position: 'absolute', right: 10, top: 30, color: '#858585', padding: '6px 8px', background: '#CECECE', borderRadius: 20 }}> <CloseOutlined /></div></Link>
    </Row>
  );
};

export default NewMaterial;
