import LogToken from './pages/logtoken/LogToken';
import Login from './pages/login/Login';
import SignUp from './pages/signup/SignUp';
import ForgotPassword from './pages/passwordReset/ForgotPassword';
import Categories from './pages/learning/categories/Categories';
import Materials from './pages/learning/materials/Materials';
import Material from './pages/learning/material/Material';
import Tests from './pages/tests/Tests';
import Test from './pages/test/Test';
import Profile from './pages/profile/Profile';
import NewTest from './pages/admin/NewTest';
import NewMaterial from './pages/admin/NewMaterial';
import AdminMaterials from './pages/admin/materials/Materials';
import AdminUsers from './pages/admin/Users';
import AdminDashboard from './pages/admin/dashboard/Dashboard';
import AdminCategories from './pages/admin/Categories';
import Reports from './pages/admin/Reports';
import Specials from './pages/admin/specials/Specials';
import ResetPassword from "./pages/passwordReset/ResetPassword";

export const mainMenuItems = [
  { to: '/learning/categories', label: 'Материалы' },
  { to: '/learning/materials', label: 'На изучении' },
];

export const getAdminMenuItems = (role: number, isAdmin: boolean) => {
  const adminMenu = [
    { roles: [], to: '/admin/dashboard', label: 'Статистика' },
    { roles: [], to: '/admin/materials', label: 'Материалы' },
    { roles: [3, 4], to: '/admin/specials', label: 'Действующие акции' },
    { roles: [], to: '/admin/categories', label: 'Категории' },
    { roles: [], to: '/admin/users', label: 'Пользователи' },
    { roles: [2, 3, 4], to: '/admin/reports', label: 'Отчеты' },
  ];
  return isAdmin ? adminMenu :
    adminMenu.filter(x => x.roles.includes(role));
}

export const routeAuthItems = (): any => {
  return [
    { type: 'user', path: '/login', element: <Login /> },
    { type: 'user', path: '/logtoken', element: <LogToken /> },
    { type: 'user', path: '/signup', element: <SignUp /> },
    { type: 'user', path: '/forgot-password', element: <ForgotPassword /> },
    { type: 'user', path: '/reset-password/:resetToken', element: <ResetPassword /> },
  ]
}

export const routeItems = (user: any): any => {
  const isAccessToUserPart = user?.role === 0 || user?.accessUserPart;
  return [
    { type: 'user', path: '/', element: <Categories /> },
    { type: 'user', path: '/logtoken', element: <LogToken /> },
    { type: 'user', path: '/learning/categories', element: <Categories /> },
    { type: 'user', path: '/learning/categories/:id', element: <Categories /> },
    { type: 'user', path: '/learning/materials/:id', element: <Materials isLearning={0} /> },
    { type: 'user', path: '/learning/materials', element: <Materials isLearning={1} /> },
    { type: 'user', path: '/learning/material/:id', element: <Material /> },
    { type: 'user', path: '/tests', element: <Tests /> },
    { type: 'user', path: '/test/:id', element: <Test /> },
    { type: 'both', path: '/profile', element: <Profile /> },
    { type: 'admin', path: '/admin/new-test/:id', element: <NewTest /> },
    { type: 'admin', path: '/admin/new-material', element: <NewMaterial /> },
    { type: 'admin', path: '/admin/new-material/:id', element: <NewMaterial /> },
    { type: 'admin', path: '/admin/materials', element: <AdminMaterials /> },
    { type: 'admin', path: '/admin/materials/:id', element: <AdminMaterials /> },
    { type: 'admin', path: '/admin/users', element: <AdminUsers /> },
    { type: 'admin', path: '/admin/dashboard', element: <AdminDashboard /> },
    { type: 'admin', path: '/admin/categories', element: <AdminCategories /> },
    { type: 'admin', path: '/admin/reports', element: <Reports /> },
    { type: 'admin', path: '/admin/specials', element: <Specials /> },
  ].map(x=>{
    if(x.type==='user' && !isAccessToUserPart) return {...x, element: <h2>Доступ закрыт, обратитесь в техподдержку.</h2>}
    if(x.type==='admin' && user?.role === 0 && !user?.isAdmin) return {...x, element: <h2>Доступ закрыт, обратитесь в техподдержку.</h2>}
    return x;
  })
};