import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { resetPassword } from '../../services/authApi';

const { Title } = Typography;

const ResetPassword: React.FC = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { resetToken } = useParams();
    const decodedResetToken = decodeURIComponent(resetToken || '');
    const navigate = useNavigate();

    useEffect(() => {
        if (!decodedResetToken) {
            message.error('Неверная ссылка для сброса пароля');
            navigate('/forgot-password');
        }
    }, []);

    const onFinish = async (values: { password: string; confirmPassword: string }) => {
        if (values.password !== values.confirmPassword) {
            message.error('Пароли не совпадают');
            return;
        }

        setIsSubmitting(true);
        try {
            await resetPassword(decodedResetToken, values.password);
            message.success('Пароль успешно изменен');
            navigate('/login');
        } catch (error) {
            console.error('Ошибка при сбросе пароля', error);
            message.error('Не удалось изменить пароль. Пожалуйста, попробуйте снова.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div style={{ maxWidth: 400, margin: '40px auto', padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ maxWidth: '400px', width: '100%', padding: '20px', textAlign: 'center' }}>
                <Title level={2}>Сброс пароля</Title>
                <Form onFinish={onFinish} layout="vertical">
                    <Form.Item
                        label="Новый пароль"
                        name="password"
                        rules={[
                            { required: true, message: 'Введите новый пароль' },
                            { min: 8, message: 'Пароль должен содержать не менее 8 символов' }, // Add this rule
                        ]}
                    >
                        <Input.Password placeholder="Введите новый пароль" />
                    </Form.Item>
                    <Form.Item
                        label="Подтвердите пароль"
                        name="confirmPassword"
                        rules={[
                            { required: true, message: 'Подтвердите новый пароль' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Пароли не совпадают'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder="Подтвердите новый пароль" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ width: '100%' }}>
                            Сбросить пароль
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default ResetPassword;