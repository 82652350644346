import React, { useState } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { requestPasswordReset } from '../../services/authApi';

const { Title, Text } = Typography;

const ForgotPassword: React.FC = () => {
    const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onFinish = async () => {
        setIsSubmitting(true);
        try {
            await requestPasswordReset(email);
            message.success('Ссылка для восстановления пароля отправлена на вашу почту, если вы уже зарегистрированы.');
        } catch (error) {
            console.error('Ошибка при отправке запроса', error);
            message.error('Не удалось отправить запрос. Пожалуйста, попробуйте снова.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div style={{ maxWidth: 400, margin: '40px auto', padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ maxWidth: '400px', width: '100%', padding: '20px', textAlign: 'center' }}>
                <Title level={2}>Восстановление пароля</Title>
                <MailOutlined style={{ fontSize: '60px', color: '#1890ff', marginBottom: '10px' }} />
                <Form onFinish={onFinish} layout="vertical">
                    <Form.Item label="Электронная почта" name="email" rules={[{ required: true, message: 'Введите почту' }]}>
                        <Input type="email" placeholder="Введите почту" onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>
                    <Text style={{ display: 'block', marginBottom: '15px', color: '#555' }}>
                        Укажите Ваш e-mail, и мы вышлем Вам ссылку для восстановления пароля.
                    </Text>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isSubmitting} style={{ width: '100%' }}>
                            Отправить
                        </Button>
                    </Form.Item>
                </Form>
                <a href="/login" style={{ display: 'block', marginTop: '10px' }}>Войти</a>
            </div>
        </div>
    );
};

export default ForgotPassword;