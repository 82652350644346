import dayjs from "dayjs";
import { message } from "antd";

const BASE_URL = "/api";
const HEADERS_JSON = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

const getToken = (): string | null => {
  return localStorage.getItem("accessToken");
};

const request = async <T>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    path: string,
    body?: any,
    isJson = true,
    responseType: "json" | "blob" = "json"
): Promise<T> => {
  const token = getToken();
  const url = `${BASE_URL}${path}`;

  try {
    const response = await fetch(url, {
      method,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
        ...(method !== "GET" && { Timestamp: dayjs().format("YYYY-MM-DDTHH:mm:ss") }),
        ...(isJson && { "Content-Type": "application/json" }),
      },
      credentials: "include",
      ...(body ? { body: isJson ? JSON.stringify(body) : body } : {}),
    });

    const data = await handleResponse(response, responseType);
    return data as T;
  } catch (error: any) {
    if (error?.errorData) {
      if (error.errorData?.message === 'TokenExpiredError') {
        const refreshed = await refreshAccessToken();
        if (refreshed) await post<T>(path, body, isJson, responseType);
      }
      throw new Error(error.errorData?.message || 'Error occurred during POST request');
    } else {
      throw new Error('Error occurred during POST request');
    }
  }
};

export const get = <T>(path: string) =>
    request<T>("GET", path);
export const post = <T>(path: string, body: any, isJson = true, responseType: "json" | "blob" = "json") =>
    request<T>("POST", path, body, isJson, responseType);
export const put = <T>(path: string, body: any, isJson = true, responseType: "json" | "blob" = "json") =>
    request<T>("PUT", path, body, isJson, responseType);
export const del = <T>(path: string, body?: any) =>
    request<T>("DELETE", path, body);

const handleResponse = async (response: Response, responseType: string) => {
  if (response.ok) {
    return responseType === "blob" ? response.blob() : response.json();
  } else {
    const errorData = await response.json();
    throw { status: response.status, errorData };
  }
};

interface RefreshTokenResponse {
  accessToken: string | undefined;
}
let refreshInProgress = false;
let pendingRequests: any[] = [];

export const refreshAccessToken = async (): Promise<boolean> => {
  if (refreshInProgress) {
    message.error("Токен обновляется. Подождите...");
    return new Promise((resolve) => {
      pendingRequests.push(resolve);
    });
  }

  refreshInProgress = true;

  try {
    const response = await post<RefreshTokenResponse>("/auth/refresh-token", {});

    if (response?.accessToken) {
      localStorage.setItem("accessToken", response.accessToken);
      pendingRequests.forEach((resolve) => resolve(true));
      pendingRequests = [];
      return true;
    } else {
      localStorage.removeItem("accessToken");
      window.location.replace("/login");
      return false;
    }
  } catch (error) {
    console.error("Error refreshing token:", error);
    pendingRequests.forEach((resolve) => resolve(false));
    pendingRequests = [];
    throw error;
  } finally {
    refreshInProgress = false;
  }
};