import React, { createContext, useContext, useState, useEffect } from 'react';
import { userDataRequest } from '../services/userApi';
import {post} from "../services/fetch";

interface AuthContextType {
  user: any;
  isAdmin: any;
  isAuthenticated: boolean;
  logout: () => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('user'));
  const [isAdmin, setIsAdmin] = useState<any>(localStorage.getItem('isAdmin'));
  const [user, setUser] = useState<any>(JSON.parse(localStorage.getItem('user') || '{}'));

  const publicRoutes = [
    '/logtoken',
    '/login',
    '/signup',
    '/forgot-password',
    '/reset-password',
  ];

  const isPublicRoute = publicRoutes.some((route) => window.location.pathname.startsWith(route));

  useEffect(() => {
    if (window.location.pathname === '/logtoken' || window.location.pathname === '/login') return;

    const isLP = getCookieValueByKey('isLP');
    const accessToken = localStorage.getItem('accessToken');
    const tokenFromLogToken = getCookieValueByKey('tokenTest');

    const urlParams = new URLSearchParams(window.location.search);
    const isLPInUrl = urlParams.get('isLP') === '1';

    if (isLP || accessToken || tokenFromLogToken) {
      getUserData();
    } else {
      if (isLPInUrl) {
        setCookie('isLP', 'true');
        getUserData();
      } else {
        logout();
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && isPublicRoute) {
      window.location.href = '/';
    }
  }, [isAuthenticated]);

  const getUserData = async () => {
    try {
      const user = await userDataRequest();
      if (user?.id) {
        localStorage.setItem('isAdmin', user?.isAdmin);
        localStorage.setItem('user', JSON.stringify(user));
        setIsAdmin(user?.isAdmin || user.role > 0);
        setUser(user);
        setIsAuthenticated(true);
      } else {
        await logout();
      }
    } catch (error) {
      await logout();
    }
  };

  const logout = async () => {
    await post('/auth/logout', {});

    const domain = window.location.hostname;
    removeTokens();
    setIsAuthenticated(false);
    setIsAdmin(0);
    setUser({});

    if (domain === 'obu.liderprodag.ru') {
      window.location.replace(!user.role ? 'https://liderprodag.ru/exit.html' : 'https://liderprodag.ru/admin?module=exit');
    } else if (!isPublicRoute) {
      window.location.href = '/login';
    }
  };

  return (
      <AuthContext.Provider value={{ user, isAdmin, isAuthenticated, logout }}>
        {children}
      </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const getCookieValueByKey = (key: string) => {
  return document.cookie
      .split('; ')
      .find((cookie) => cookie.startsWith(`${key}=`))
      ?.split('=')[1] || null;
};

const setCookie = (key: string, value: string, days: number = 30) => {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  document.cookie = `${key}=${value};expires=${date.toUTCString()};path=/`;
};

const deleteCookie = (key: string) => {
  document.cookie = `${key}=; Max-Age=-99999999; path=/`;
};

const removeTokens = (): void => {
  localStorage.removeItem('accessToken');
  localStorage.setItem('isAdmin', '0');
  localStorage.setItem('isAdminMode', '0');
  localStorage.removeItem('user');
  deleteCookie('isLP');
  deleteCookie('token');
  deleteCookie('tokenTest');
};