import {get, post, put} from "./fetch";

export const sendCapcha = async (capcha: string): Promise<any> => {
  try {
    const response = await post('/user/capcha', {capcha});
    const data = response as any;
    return data;
  } catch (error) {
    throw new Error('Error occurred during login');
  }
};

export const userDataRequest = async (): Promise<any> => {
  return await get('/user/data');
};

export const userList = async (): Promise<any> => {
  try {
    return await get('/user/list');
  } catch (error) {
    throw new Error('Error occurred while fetching material');
  }
};

export const changeAccessUserPart = async (userId: number, accessUserPart: boolean): Promise<boolean> => {
  return get(`/user/changeAccessUserPart?userId=${userId}&accessUserPart=${accessUserPart}`);
};

export const changeUserStatus = async (userId: number, newStatus: string): Promise<boolean> => {
  return put(`/user/status`, { userId, newStatus });
};