import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Button, Table, Modal, Select, Checkbox, Spin } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import Uploader from '../../components/imgUploader';
import type { UploadFile, RcFile } from 'antd/es/upload/interface';
import { categoryListTree, categoryDelete, categoryListByCanAddMaterial } from '../../services/categoryApi';
import { post } from '../../services/fetch';

interface DataType {
  key: string;
  name: string;
  popularity: number;
  isPublished: boolean;
}

const Categories: React.FC = () => {
  useEffect(()=>{getData()}, [])
  const getData = () =>  {
    setIsLoading(true);
    categoryListTree().then(data=>{
      setIsLoading(false);
      setData(data);
    })
    categoryListByCanAddMaterial(0).then(data=>setParents(data));
  }

  const categTemplate = {parent: '0', title: '', id: null, canAddMaterials: false};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [currentCategory, setCurrentCategory] = useState(categTemplate);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [parents, setParents] = useState<any>([]);

  const handleEdit = (categ: any) => {
    setCurrentCategory({parent: categ.parentCategId+'', title: categ.name, id: categ.id, canAddMaterials: categ.canAddMaterials})
    setFileList([{
      uid: '-1',
      name: categ.imgSource,
      status: 'done',
      url: '/api/public/categories/'+categ.imgSource,
    },])
    setIsModalOpen(true);
  }

  const handleDelete = async (id: number) => {
    let result = {status: ''};
    if(window.confirm('Вы уверены?')) result = await categoryDelete(id);
    if(result.status === 'has children') alert('Нельзя удалять категорию, которая содержит подкатегории')
    if(result.status === 'success') getData();
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (text) => <div>{text}</div>,
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: '',
      width: '75px',
      dataIndex: 'id',
      key: 'id',
      render: (id, record) => <div>
        <span onClick={()=>handleDelete(id)} style={{color: '#A40207', cursor: 'pointer'}}><DeleteOutlined style={{ fontSize: 18 }} /></span>
        <span onClick={()=>handleEdit(record)} style={{color: '#A40207', cursor: 'pointer'}}><EditOutlined style={{ float: 'right', fontSize: 18 }} /></span>
        </div>
    }
  ];

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentCategory(categTemplate);
  }

  const handleSave = () => {
    if(!currentCategory.title || fileList.length === 0) {
      alert('Заполните все поля');
      return;
    }
    setIsLoading(true)
    const formData = new FormData();
    formData.append('file', fileList[0] as RcFile);
    formData.append('title', currentCategory.title);
    formData.append('parent', currentCategory.parent);
    formData.append('canAddMaterials', +currentCategory.canAddMaterials+'');
    if(currentCategory.id) formData.append('id', currentCategory.id);
    post('/category/update', formData, false)
      .then(() => {
        console.log('upload successfully.');
      })
      .catch(() => {
        console.error('upload failed.');
      })
      .finally(() => {
        setFileList([]);
        setIsLoading(false);
        closeModal();
        getData();
      });
  };
  return (
    <Spin spinning={isLoading}>
      <Row gutter={[16, 16]} style={{ marginTop: 40 }} >
        <Col span={16} style={{ fontSize: 25 }}>Категории</Col>
        <Col span={8}><Button style={{ width: '100%' }} onClick={() => setIsModalOpen(true)}>Добавить <PlusOutlined /></Button></Col>
        <Col span={24}><Table columns={columns} dataSource={data} rowKey='id' /></Col>
      </Row>
      {isModalOpen && <Modal
        title='Добавление новой категории'
        open={true}
        onCancel={() => {setFileList([]);closeModal()} }
        footer={[<Button loading={isLoading} onClick={handleSave}> Сохранить </Button>]}
      >
        <div style={{ margin: '15px 0' }}>
          Родительская категория:
          <Select 
            value={currentCategory.parent} 
            onChange={(value)=>setCurrentCategory({...currentCategory, parent: value})} 
            style={{ width: '100%' }} 
            options={[
              { value: '0', label: 'Отсутствует' },
              ...parents.map((x: any)=>({value: x.id, label: x.name}))
            ]} 
          />
        </div>
        <div style={{ marginBottom: 15 }}>
          Название новой категории:
          <Input value={currentCategory.title} onChange={(e)=>setCurrentCategory({...currentCategory, title: e.target.value})}/>
        </div>
        <div style={{ marginBottom: 15 }}>
          Можно добавлять материалы: 
          <Checkbox style={{marginLeft: 8}} checked={currentCategory.canAddMaterials} onChange={(e)=>setCurrentCategory({...currentCategory, canAddMaterials: e.target.checked})}/>
        </div>
        Изображение:
        <Uploader fileList={fileList} setFileList={setFileList}/>
      </Modal >}
    </Spin>
  );
};

export default Categories;
