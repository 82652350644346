import React, { useEffect, useState } from 'react';
import { Row, Col, Collapse, Pagination } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import { testStat } from '../../services/testApi';

interface Stat {
    userData: {
        balance: number;
        extraData: { points: number },
        type: number,
    }
}

const ProfileStat: React.FC<Stat> = (props) => {
  const pageSize = 5;
  const [stat, setStat] = useState({ success: [], notSuccess: [] })
  const [successPage, setSuccessPage] = useState(1);
  useEffect(() => {
    testStat().then(result => {
      setStat(result);
    })
  }, [])

  const successPageChange = (page: number) => {
    setSuccessPage(page)
  }

  const { balance, extraData, type } = props.userData;
  return (
    <div >
      <Row style={{ padding: '14px 20px' }}>
        <Col xs={12} sm={12} xl={12} xxl={8}><b>Успешно пройдено тестов:</b></Col><Col xs={12} sm={12} xl={2} style={{ textAlign: 'right' }}> {stat.success.filter((x:any)=>x.prize>0).length}</Col>
      </Row>
      <Row style={{ padding: '14px 20px', marginBottom: 15 }}>
        <Col xs={12} sm={12} xl={12} xxl={8}><b>Доступно баллов:</b></Col><Col xs={12} sm={12} xl={2} style={{ textAlign: 'right' }}> {type === 1 ? extraData.points : balance}</Col>
      </Row>
      <Collapse
        className='profile-stat-collapse'
        expandIconPosition='end'
        bordered={false}
        style={{ background: '#DCFCE7' }}
        defaultActiveKey={[1]}
        expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} style={{ fontSize: 18 }} />}
        items={[{
          key: '1',
          label: 'Завершенных: '+stat.success.length,
          style: { fontSize: 18, padding: '10px 12px' },
          children:
            <Row gutter={[16, 16]} style={{ padding: '14px 4%' }}>
              {stat.success.slice((successPage - 1) * pageSize, successPage * pageSize).map((x:any) =>
                <><Col span={20}>{x.name}</Col><Col span={4} style={{ textAlign: 'right' }}> {x.prize}</Col></>
              )}
            </Row>
        }]}
      />
       {!!stat.success.length && <Pagination current={successPage} total={stat.success.length} pageSize={pageSize} showSizeChanger={false} style={{ textAlign: 'center', margin: '20px 0' }} onChange={successPageChange} />}
    </div>
  );
};

export default ProfileStat;